<template>
    <v-dialog v-model="viewOps.dialog" max-width="550px">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col cols="10">
                        <span>View Client</span>
                    </v-col>
                    <v-col cols="2">
                        <div class="float-right">
                            <CustomButton
                                btnIcon="mdi-close"
                                tooltipMsg="Close"
                                @click="hideDialog"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider />

            <v-card-text class="px-4">
                <v-card flat>
                    <v-card-text>
                        <ValidationObserver ref="observer" v-slot="{}">
                            <v-form>
                                <v-row dense>
                                    <v-col>
                                        <CustomLabel for="name" label="Name" />
                                        <CustomTextField
                                            id="name"
                                            inputPlaceholder="Name"
                                            :inputValue="client.name"
                                            validationName="name"
                                            validationRules="required"
                                            @input="
                                                updateForm(
                                                    $event,
                                                    'client.name'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <CustomButton
                                            btnLabel="Cancel"
                                            tooltipMsg="Cancel"
                                            @click="hideDialog"
                                        />
                                        <CustomButton
                                            btnColour="primary"
                                            :btnIsLoading="updateOps.isLoading"
                                            btnLabel="Save"
                                            tooltipMsg="Save"
                                            @click="updateItem(client)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </ValidationObserver>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("clientsAdminMgmt", ["client", "updateOps", "viewOps"]),
    },
    data: () => ({}),
    methods: {
        hideDialog() {
            this.$store.commit("clientsAdminMgmt/HIDE_VIEW_OPS_DIALOG");
            this.$store.commit("clientsAdminMgmt/FLUSH_ITEM");
        },
        async updateItem(payload) {
            let valid = await this.$refs.observer.validate();

            if (!valid) {
                return;
            }

            let response = await this.$store.dispatch(
                "clientsAdminMgmt/updateItem",
                {
                    id: payload.id,
                    client: {
                        name: payload.name,
                    },
                }
            );

            if (response) {
                this.$refs.observer.reset();
            }
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("clientsAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("clientsAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
    },
    name: "DialogClientView",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
